"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallVoucherApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var mallVoucherApi = exports.mallVoucherApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/mallVoucher",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/mallVoucher/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/mallVoucher",
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/mallVoucher/".concat(id, "/update"),
      method: 'post',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/mallVoucher/".concat(id),
      method: 'delete'
    });
  }
};