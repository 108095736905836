"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawMallApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var withdrawMallApi = exports.withdrawMallApi = {
  findAll: function findAll(params, hash) {
    return (0, _request.default)({
      url: "/withdrawMall",
      params: params,
      headers: {
        "hash-pin-code": hash
      }
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/withdrawMall",
      method: "post",
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/withdrawMall/".concat(id, "/cancel"),
      method: "delete"
    });
  }
};