"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.returnCarrierTypeTrans = exports.ReturnCarrierType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ReturnCarrierType;
(function (ReturnCarrierType) {
  ReturnCarrierType["GHTK"] = "GHTK";
  ReturnCarrierType["GHN"] = "GHN";
})(ReturnCarrierType || (exports.ReturnCarrierType = ReturnCarrierType = {}));
var returnCarrierTypeTrans = exports.returnCarrierTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, ReturnCarrierType.GHN, {
  title: "Giao hàng nhanh",
  value: ReturnCarrierType.GHN
}), ReturnCarrierType.GHTK, {
  title: "Giao hàng tiết kiệm",
  value: ReturnCarrierType.GHTK
});