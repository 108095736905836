"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallBannerTypeTrans = exports.MallBannerType = exports.BannerLayout = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallBannerType;
(function (MallBannerType) {
  MallBannerType["MallProduct"] = "MALL_PRODUCT";
  MallBannerType["Mall"] = "MALL"; //Chỉ định 1 cửa hàng mall
})(MallBannerType || (exports.MallBannerType = MallBannerType = {}));
var MallBannerTypeTrans = exports.MallBannerTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallBannerType.Mall, 'Cửa hàng mall'), MallBannerType.MallProduct, 'Sản phẩm');
var BannerLayout;
(function (BannerLayout) {
  BannerLayout["Top"] = "TOP";
  BannerLayout["Bottom"] = "BOTTOM";
})(BannerLayout || (exports.BannerLayout = BannerLayout = {}));