"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.string.includes.js");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    pathImage: {
      type: String
    },
    baseUrlImage: {
      type: String,
      default: process.env.VUE_APP_BASE_URL_MEDIA
    },
    urlUpload: {
      type: String,
      default: "/drivers/upload"
    },
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 180
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: "file"
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      isProgressing: false,
      percentUpload: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  methods: {
    handlePreviewImage: function handlePreviewImage() {
      this.$refs.imagePreview.$el.children[0].click();
    },
    onProgress: function onProgress(event, file, fileList) {
      this.isProgressing = true;
      this.percentUpload = parseInt(event.percent.toFixed(0));
    },
    onError: function onError(err, file, fileList) {
      if (err.message) {
        this.$message.error(err.message);
      }
      this.isProgressing = false;
    },
    handleSuccess: function handleSuccess(res) {
      this.$emit("upload:success", res.data.path);
      this.isProgressing = false;
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.$message.error("Image is not valid format!");
      }
      return isJPG;
    }
  }
};