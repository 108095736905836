"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallProductUsedStatusTrans = exports.MallProductUsedStatus = exports.MallProductPropertySelectType = exports.MallProductPricingType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallProductPricingType;
(function (MallProductPricingType) {
  MallProductPricingType["Single"] = "SINGLE";
  MallProductPricingType["Multiple"] = "MULTIPLE";
})(MallProductPricingType || (exports.MallProductPricingType = MallProductPricingType = {}));
var MallProductPropertySelectType;
(function (MallProductPropertySelectType) {
  MallProductPropertySelectType["Single"] = "SINGLE";
  MallProductPropertySelectType["Multiple"] = "MULTIPLE";
})(MallProductPropertySelectType || (exports.MallProductPropertySelectType = MallProductPropertySelectType = {}));
var MallProductUsedStatus;
(function (MallProductUsedStatus) {
  MallProductUsedStatus["New"] = "NEW";
  MallProductUsedStatus["Used"] = "USED";
})(MallProductUsedStatus || (exports.MallProductUsedStatus = MallProductUsedStatus = {}));
var MallProductUsedStatusTrans = exports.MallProductUsedStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallProductUsedStatus.New, "Mới"), MallProductUsedStatus.Used, "Đã sử dụng");