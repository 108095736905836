"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHash = getHash;
exports.getToken = getToken;
exports.removeHash = removeHash;
exports.removeToken = removeToken;
exports.setHash = setHash;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _moment = _interopRequireDefault(require("moment"));
var THIRTY_MINUTE = 30;
var TokenKey = "Admin-Token";
var HashKey = "Admin-Hash";
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function setHash(hash, expiredUnix) {
  var hashExpired = _moment.default.unix(expiredUnix).toDate();
  return _jsCookie.default.set(HashKey, hash, {
    expires: hashExpired
  });
}
function getHash() {
  return _jsCookie.default.get(HashKey);
}
function removeHash() {
  return _jsCookie.default.remove(HashKey);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}