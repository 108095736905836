"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.complaintStatusTrans = exports.ComplaintStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ComplaintStatus;
(function (ComplaintStatus) {
  ComplaintStatus["solved"] = "SOLVED";
  ComplaintStatus["rejected"] = "REJECT";
  ComplaintStatus["pending"] = "PENDING";
})(ComplaintStatus || (exports.ComplaintStatus = ComplaintStatus = {}));
var complaintStatusTrans = exports.complaintStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ComplaintStatus.solved, {
  label: "Chấp nhận khiếu nại",
  color: "success",
  value: ComplaintStatus.solved
}), ComplaintStatus.rejected, {
  label: "Từ chối khiếu nại",
  color: "danger",
  value: ComplaintStatus.rejected
}), ComplaintStatus.pending, {
  label: "Chờ duyệt",
  color: "",
  value: ComplaintStatus.pending
});