"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _enum = require("@/utils/enum");
var _user = require("@/api/user");
var _bank = require("@/types/bank");
var _PinDialog = _interopRequireDefault(require("@/views/order/components/PinDialog.vue"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PinDialog: _PinDialog.default
  },
  data: function data() {
    return {
      hash: (0, _auth.getHash)(),
      bankList: _bank.bankList,
      STORE_TYPE: _enum.STORE_TYPE,
      loading: false,
      form: (0, _objectSpread2.default)({}, this.$store.state.user.info),
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên cửa hàng",
          trigger: "submit"
        }
        // address: {
        //   required: true,
        //   message: "Vui lòng nhập địa chỉ",
        //   trigger: "submit"
        // },
        // type: {
        //   required: true,
        //   trigger: "submit",
        //   message: "Vui lòng chọn loại cửa hàng"
        // },
      }
    };
  },
  computed: {
    status: function status() {
      return this.form.status == _enum.StoreStatus.Online;
    }
  },
  mounted: function mounted() {
    if (!this.hash) {
      var profile = this.$store.state.user.info;
      if (profile.isHasPinCode) {
        return this.handleShowPinDialog();
      }
    }
  },
  methods: {
    handleShowPinDialog: function handleShowPinDialog() {
      return this.$refs.PinDialog.handleEnterPin();
    },
    handleValidated: function handleValidated(e) {
      if (!e) return;
      this.hash = (0, _auth.getHash)();
      this.getTableData();
    },
    handleUpdate: function handleUpdate() {
      var _this = this;
      var data = {
        mall: this.form
      };
      this.loading = true;
      (0, _user.updateProfile)(data).then(function () {
        _this.$notify({
          type: "success",
          title: "Thông báo",
          message: "Cập nhật thành công"
        });
        _this.$store.dispatch("user/getInfo");
      }).catch(function () {}).finally(function () {
        _this.loading = false;
      });
    }
  }
};