"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flashSaleScheduleApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var flashSaleScheduleApi = exports.flashSaleScheduleApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/flashSaleSchedule",
      params: params
    });
  }
};