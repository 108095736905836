"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var transactionApi = exports.transactionApi = {
  findAll: function findAll(params, hash) {
    return (0, _request.default)({
      url: "/transactionMall",
      headers: {
        "hash-pin-code": hash
      },
      params: params
    });
  }
};