"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conversationApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var conversationApi = exports.conversationApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/conversation",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/conversation",
      data: data,
      method: 'post'
    });
  },
  message: function message(id, params) {
    return (0, _request.default)({
      url: "/conversation/".concat(id, "/message"),
      params: params
    });
  },
  chat: function chat(id, data) {
    return (0, _request.default)({
      url: "/conversation/".concat(id, "/chat"),
      method: 'post',
      data: data
    });
  }
};