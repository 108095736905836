"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.findOne = findOne;
exports.getInfo = getInfo;
exports.getList = getList;
exports.login = login;
exports.logout = logout;
exports.offline = offline;
exports.online = online;
exports.reset = reset;
exports.update = update;
exports.updatePassword = updatePassword;
exports.updateProfile = updateProfile;
var _request = _interopRequireDefault(require("@/utils/request"));
var oneSignalPlayerId = localStorage.getItem("OneSignalId");
function login(data) {
  return (0, _request.default)({
    url: "/auth/login",
    method: "post",
    data: data
  });
}
function getInfo() {
  return (0, _request.default)({
    url: "/auth/profile",
    method: "get",
    headers: {
      oneSignalPlayerId: oneSignalPlayerId
    }
  });
}
function updateProfile(data) {
  return (0, _request.default)({
    url: "/auth/profile",
    method: "post",
    data: data
  });
}
function reset(id, data) {
  return (0, _request.default)({
    url: "/users/".concat(id, "/resetPassword"),
    method: "post",
    data: data
  });
}
function findOne(userId) {
  return (0, _request.default)({
    url: "/users/".concat(userId),
    method: "get"
  });
}
function updatePassword(data) {
  return (0, _request.default)({
    url: "/auth/profile/password/update",
    method: "post",
    data: data
  });
}
function getList(listQuery) {
  return (0, _request.default)({
    url: "/users",
    method: "get",
    params: listQuery
  });
}
function add(data) {
  return (0, _request.default)({
    url: "/users",
    method: "post",
    data: data
  });
}
function update(userId, data) {
  return (0, _request.default)({
    url: "/users/".concat(userId, "/update"),
    method: "post",
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: "/user/logout",
    method: "post"
  });
}
function online() {
  return (0, _request.default)({
    url: "/auth/online",
    method: "post"
  });
}
function offline() {
  return (0, _request.default)({
    url: "/auth/offline",
    method: "post"
  });
}