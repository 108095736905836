"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallPaymentTypeTrans = exports.MallPaymentType = exports.MallOrderStatusTrans = exports.MallOrderStatus = exports.MallOrderProductTypeTrans = exports.MallOrderProductType = exports.CarrierType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028mall/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CarrierType;
(function (CarrierType) {
  CarrierType["GHTK"] = "GHTK";
})(CarrierType || (exports.CarrierType = CarrierType = {}));
var MallOrderProductType;
(function (MallOrderProductType) {
  MallOrderProductType["MallProduct"] = "MALL_PRODUCT";
  MallOrderProductType["EVoucher"] = "E_VOUCHER";
})(MallOrderProductType || (exports.MallOrderProductType = MallOrderProductType = {}));
var MallOrderProductTypeTrans = exports.MallOrderProductTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallOrderProductType.MallProduct, "Sản phẩm mall"), MallOrderProductType.EVoucher, "E-Voucher");
var MallPaymentType;
(function (MallPaymentType) {
  MallPaymentType["Cod"] = "COD";
  MallPaymentType["Balance"] = "BALANCE";
  MallPaymentType["VnPay"] = "VNPAY";
  MallPaymentType["Momo"] = "MOMO";
  MallPaymentType["Cash"] = "CASH";
})(MallPaymentType || (exports.MallPaymentType = MallPaymentType = {}));
var MallPaymentTypeTrans = exports.MallPaymentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallPaymentType.Momo, "Thanh toán MoMo"), MallPaymentType.VnPay, "Thanh toán VnPay"), MallPaymentType.Balance, "Thanh toán bằng tủ Fclass"), MallPaymentType.Cod, "Thanh toán khi nhận hàng"), MallPaymentType.Cash, "Thanh toán khi nhận hàng");
var MallOrderStatus;
(function (MallOrderStatus) {
  MallOrderStatus["Pending"] = "PENDING";
  MallOrderStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  MallOrderStatus["MallCancel"] = "MALL_CANCEL";
  MallOrderStatus["Delivering"] = "DELIVERING";
  MallOrderStatus["DeliveryComplete"] = "DELIVERY_COMPLETE";
  MallOrderStatus["Packing"] = "PACKING";
  MallOrderStatus["Complete"] = "COMPLETE";
  MallOrderStatus["Refund"] = "REFUND";
  MallOrderStatus["Confirm"] = "CONFIRM";
  MallOrderStatus["Delay"] = "DELAY";
})(MallOrderStatus || (exports.MallOrderStatus = MallOrderStatus = {}));
var MallOrderStatusTrans = exports.MallOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallOrderStatus.Pending, "Chờ shop xác nhận"), MallOrderStatus.CustomerCancel, "Khách hủy"), MallOrderStatus.MallCancel, "Shop hủy"), MallOrderStatus.Delivering, "Đang giao"), MallOrderStatus.DeliveryComplete, "Đã giao"), MallOrderStatus.Packing, "Chờ lấy hàng"), MallOrderStatus.Complete, "Hoàn tất"), MallOrderStatus.Refund, "Hoàn trả"), MallOrderStatus.Confirm, "Shop xác nhận"), MallOrderStatus.Delay, "Delay");